type inputsPermitidos = HTMLInputElement | HTMLSelectElement |HTMLTextAreaElement
interface formField {
    input: inputsPermitidos
    error: HTMLParagraphElement
    fieldName: string
}

const NUMERO_WHATSAPP = '3493413196'
const formElement = document.querySelector('#contactForm')as HTMLFormElement

const fieldNombreApellido = document.querySelector('#fieldNombreApellido') as HTMLInputElement
const errorNombreApellido = document.querySelector('#errorNombreApellido') as HTMLParagraphElement

const fieldEmail = document.querySelector('#fieldEmail') as HTMLInputElement
const errorEmail = document.querySelector('#errorEmail') as HTMLParagraphElement

const fieldTelefonoPresupuesto = document.querySelector('#fieldTelefonoPresupuesto') as HTMLInputElement
const errorTelefonoPresupuesto = document.querySelector('#errorTelefonoPresupuesto') as HTMLParagraphElement

const fieldTelefonoContacto = document.querySelector('#fieldTelefonoContacto') as HTMLInputElement
const errorTelefonoContacto = document.querySelector('#errorTelefonoContacto') as HTMLParagraphElement

const fieldTipoPresupuesto = document.querySelector('#fieldTipoPresupuesto') as HTMLSelectElement
const errorTipoPresupuesto = document.querySelector('#errorTipoPresupuesto') as HTMLParagraphElement

const fieldMensaje = document.querySelector('#fieldMensaje') as HTMLTextAreaElement
const errorMensaje = document.querySelector('#errorMensaje') as HTMLParagraphElement

const mensajeFormularioExito = document.querySelector('#mensajeFormularioExito') as HTMLElement
const deleteMensajeFormularioExito = document.querySelector('.deleteMensajeFormularioExito') as HTMLButtonElement

const mensajeFormularioError = document.querySelector('#mensajeFormularioError') as HTMLElement
const deleteMensajeFormularioError = document.querySelector('.deleteMensajeFormularioError') as HTMLButtonElement

const fields: formField[] = [
    { input: fieldNombreApellido, error: errorNombreApellido, fieldName: 'nombreApellido' },
    { input: fieldEmail, error: errorEmail, fieldName: 'email' },
    { input: fieldTelefonoPresupuesto, error: errorTelefonoPresupuesto, fieldName: 'telefonoPresupuesto' },
    { input: fieldTelefonoContacto, error: errorTelefonoContacto, fieldName: 'telefonoContacto' },
    { input: fieldTipoPresupuesto, error: errorTipoPresupuesto, fieldName: 'tipoPresupuesto' },
    { input: fieldMensaje, error: errorMensaje, fieldName: 'mensaje' }
]

function mostrarLimpiarErrores (mostrar = true) {
    fields.forEach((el) => {
        if ( (el.input && el.error) ) {
            if (mostrar) {
                mostrarErrorCuandoInputEsInvalido(el.input, el.error)
            } else {
                refrescarError(el.input, el.error)
            }
        }
    })
}

function mostrarErrorCuandoInputEsInvalido (input: inputsPermitidos, errorElement: HTMLParagraphElement) {
    if (!input.validity.valid) {
        input.classList.add('is-danger')
        errorElement.style.visibility = 'visible'
    }
}

function refrescarError (input: inputsPermitidos, errorElement: HTMLParagraphElement) {
    input.classList.remove('is-danger')
    errorElement.style.visibility = 'hidden'
}

function obtenerValoresDeFieldsYArmarStringURLWhatsapp () {
    let str = ''
    for (const field of fields) {
        if (field.input) {
            switch (field.fieldName) {
                case 'nombreApellido':
                    str += `Nombre: ${ field.input.value }\n`
                    continue
                case 'email':
                    str += `Email: ${ field.input.value }\n`
                    continue
                case 'telefonoPresupuesto':
                    str = ' # Presupuesto Web #\n' + str
                    str += `Tel: ${ field.input.value }\n`
                    continue
                case 'telefonoContacto':
                    str = ' # Contacto Web #\n' + str
                    str += `Tel: ${ field.input.value }\n`
                    continue
                case 'tipoPresupuesto':
                    str += `Tipo: ${ field.input.value }\n`
                    continue
                case 'mensaje':
                    str += `Mensaje: \n${ field.input.value }\n`
                    continue
                default:
                    continue
            }
        }
    }
    return str
}

function registrarEventoBlurEnFieldsParaRefrescarErrores () {
    fields.forEach((el) => {
        if (el.input && el.error) {
            el.input.addEventListener('focus', () => {
                refrescarError(el.input, el.error)
            })
        }
    })
}

function resetearValoresFields () {
    fields.forEach((el) => {
        if (el.input) {
            el.input.value = ''
        }
    })
}

function toggleMensajeFormulario (tipoMensaje: 'exito' | 'error', mostrar: boolean) {
    if (tipoMensaje === 'exito') {
        if (mostrar) {
            mensajeFormularioExito.classList.remove('is-hidden')
            mensajeFormularioExito.scrollIntoView(true)
        } else {
            mensajeFormularioExito.classList.add('is-hidden')
        }
    }
    if (tipoMensaje === 'error') {
        if (mostrar) {
            mensajeFormularioError.classList.remove('is-hidden')
            mensajeFormularioError.scrollIntoView(true)
        } else {
            mensajeFormularioError.classList.add('is-hidden')
        }
    }
}

function ocultarMensajesFormulario () {
    toggleMensajeFormulario('exito', false)
    toggleMensajeFormulario('error', false)
}

function registrarEventosBotonesDeleteMensajesFormulario () {
    deleteMensajeFormularioExito.addEventListener('click', () => {
        toggleMensajeFormulario('exito', false)
    })
    deleteMensajeFormularioError.addEventListener('click', () => {
        toggleMensajeFormulario('error', false)
    })
}

if (formElement) {
    formElement.addEventListener('submit', (e: SubmitEvent) => {
        e.preventDefault()
        ocultarMensajesFormulario()
        mostrarLimpiarErrores(false)

        // Chequear que todos los fields sean válidos.
        for (const field of fields) {
            if (field.input && !field.input.validity.valid) {
                mostrarLimpiarErrores()
                return
            }
        }
        // Obtenemos la información de los inputs y armamos el String que se enviará por la URL a Whatsapp (Codificamos con URI).
        const mensajeWhatsappCodificadoURI = window.encodeURIComponent(obtenerValoresDeFieldsYArmarStringURLWhatsapp())
        const windowObjectReference  = window.open(`https://wa.me/54${NUMERO_WHATSAPP}?text=${mensajeWhatsappCodificadoURI}`, '_blank')
        if (typeof (windowObjectReference ) !== null) {
            // Se abrió Whatsapp correctamente: Limpiar formulario y mostrar mensaje de éxito.
            // Mostrar mensaje de éxito y hacerle focus.
            resetearValoresFields()
            toggleMensajeFormulario('exito', true)
        } else {
            // Mostrar mensaje de error.
            console.log('Window object reference returned null')
            toggleMensajeFormulario('error', true)
        }

    })
    registrarEventoBlurEnFieldsParaRefrescarErrores()
    registrarEventosBotonesDeleteMensajesFormulario()
}
